import styled from "styled-components"
import Box from "shared/components/Box"
import zIndex from "shared/utils/zIndexLevels"
import { sm } from "mill/utils/breakpoints"

export const RadioButton = styled(Box)`
  cursor: pointer;
  position: relative;
  margin: 0 0.3rem;

  @media ${sm} {
    margin: 0;
  }

  input[type="radio"] {
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: ${zIndex.base + 1};
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;

    + label {
      &:before {
        content: '';
        display: block;
        cursor: pointer;
        border-radius: 100%;
        border: 1px solid ${props => props.theme.colors.primary};
        width: 2.4rem;
        height: 2.4rem;
        position: relative;
        top: 0;
        margin-bottom: 0.6rem;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + label {
        &:before {
          background-color: ${props => props.theme.colors.primary};
          box-shadow: inset 0 0 0 4px white;
        }
      }
    }
    &:focus {
      + label {
        &:before {
          outline: none;
          border-color: ${props => props.theme.colors.primary};
        }
      }
    }
  }
`
